import React from "react";
import { graphql } from "gatsby";
import Footer from "../components/Footer/Footer";
import Post from "../components/Post/Post";
import { PostSEO } from "../components/SEO/SEO";
import Topbar from "../components/Topbar/Topbar";
import Layout from "../components/layout";

export default class PostTemplate extends React.Component {
  render() {
    const { post, categories } = this.props.data;

    return (
      <Layout>
        <PostSEO page="post" post={post} />
        <Topbar
          title={post.title}
          category={post.category}
          subcategory={post.subcategory}
          categories={categories.edges}
          sharePath={`/${post.slug}/`}
          shareImageId={post.ogImageId}
          shareTitle={post.title}
        />
        <Post post={post} products={post.products} />
        <Footer related={post.relateds} />
      </Layout>
    );
  }
}

/* eslint no-undef: "off" */
export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    post: crossCastPost(slug: { eq: $slug }) {
      slug
      title
      alias
      datePublished
      category {
        title
        slug
      }
      subcategory {
        title
        slug
      }
      author
      dateModified
      ogImageId: coverId
      metaDescription
      remark: childMarkdownRemark {
        htmlAst
        timeToRead
      }
      products {
        id
        title
        brand
        imageId
        description
        price
        cta
        ctaLabel
        label
      }
      relateds {
        guid
        slug
        title
        category {
          title
          slug
        }
        coverId
        coverAlt
        coverTitle
      }
    }
    categories: allCrossCastCategory(
      filter: { parentCategory: { slug: { eq: null } } }
    ) {
      edges {
        node {
          slug
          title
          position
          subcategories {
            slug
            title
          }
        }
      }
    }
  }
`;
